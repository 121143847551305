import React, { useEffect, useState } from 'react'
import "./Blog.css";
import { NavLink } from 'react-router-dom';
const Blog = () => {
  const [postType, setPostType] = useState("publish");
  const [blogData, setBlogData] = useState([])
  const [date,setDate] = useState("")
  const fetchBlogData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_PROD_URL}/ColorBotGetAllBlog`);
      if (response.ok) {
        const result = await response.json();
        console.log(result);

        setBlogData(result);
      }
    } catch (error) {

    }


  };

  useEffect(() => {


    fetchBlogData();
  }, []);
  function formatDate(dateStr) {
    const date = new Date(dateStr);
    return new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    }).format(date);
  }


  const DeleteBlogData = async (id) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_PROD_URL}/ColorBotDeleteBlog/${id}`,{
        method: 'Delete',
        headers: {
          'Authorization': `Bearer ${window.localStorage.getItem('token')}`
        },
       
      });
      if (response.ok) {
       
       fetchBlogData()
      }
    } catch (error) {

    }


  };

  const filterByDate = (value)=>{
    console.log(value);
    setDate(value)
    console.log(blogData.filter((item)=>item.createdAt.includes(value)));
    setBlogData(blogData.filter((item)=>item.createdAt.includes(value)))
  }
  return (
    <section className="bg-color1">
      <div className="container p-4">
        <div className="row">
          <div className="col-lg-12 admin-blog d-flex space-between ">
            <div className="left-content">
              <h2>Post</h2>
            </div>
            <div className="ryt-content" style={{display:"flex",justifyContent:"space-between",alignItems:"center",gap:"10px"}}>
              <input className='blog-date' value={date} type='date' onChange={(e)=>{
                filterByDate(e.target.value)
              }}></input>
               {date&&<p className="product-name" style={{
                margin:"0px",
                cursor:"pointer"
               }} onClick={()=>{
                  setDate("")
                  fetchBlogData()
               }}>Clear Filter</p>}
            </div>
          </div>
          <div className="col-lg-12">
            <div className="admin-blog-heading">
              <div className="d-flex active1">
                <p className={`heading1 ${postType === "publish" ? "headingactive" : ""}`} onClick={() => {
                  setPostType("publish")
                }}>Published</p>
                <p className={`heading2 ${postType === "draft" ? "headingactive1 " : ""}`} onClick={() => {
                  setPostType("draft")
                }}>Draft</p>
              </div>
              <div className="line"></div>
            </div>
          </div>
          {blogData.filter((item)=>item.PostType === postType).map((blog, index) => (
            <div className="col-lg-12 mt-4 d-flex row-content-top" style={{ background: "#F6F7FF" }} key={index}>
              <div className="row-content d-flex">
                <span className="s-n">{index+1}</span>
                <img src="assets/fan.svg" className="image" />
                <p className="product-name">{blog?.Title}<br />
                  <span className="tip mt-2 "> {formatDate(blog?.createdAt)}</span>
                </p>
              </div>
              <div className="icons d-flex">
                <NavLink to={`/EditNewBlog/${blog.blogId}`}><i class="fa fa-pencil" aria-hidden="true"></i></NavLink>
                <i class="fa fa-trash" aria-hidden="true" style={{
                  cursor:"pointer"
                }} onClick={()=>{
                  DeleteBlogData(blog.blogId)
                }}></i>
              </div>
            </div>
          ))}

          {
            blogData.filter((item)=>item.PostType === postType).length === 0 && 
            <div className="col-lg-12 mt-4 d-flex row-content-top" style={{ background: "white" }}>
              <div className="row-content d-flex">
                
                <p className="product-name">No {postType} blog found</p>
              </div>
              </div>
          }

          
          
          
        </div>

      </div>

    </section>
  )
}
export default Blog;
