import React, { useState } from 'react'
import "./Adminlogin.css";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Adminlogin = () => {
    const [username, setUsername] = useState(" ");
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [password, setPassword] = useState(" ");
    const navigate = useNavigate();
    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    }
    
    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
    }
    const handleSubmit = async () => {
            console.log(process.env.REACT_APP_PROD_URL)
        if (username !== "" && password !== "") {
            try {
                const response = await fetch(`${process.env.REACT_APP_PROD_URL}/ColorBotCMSAdminlogin`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ UserName: username, Password: password }),
                });
                const data = await response.json();
                
                if (data.status === 200) {
                    window.localStorage.setItem("isLoggedIn", true);
                    window.localStorage.setItem("username", data.data.Username);
                    window.localStorage.setItem("token",data.data.token)
                    window.localStorage.setItem("role",data.data.Role)
                    window.localStorage.setItem("status",data.data.Status)
                    window.localStorage.setItem("email",data.data.Email)
                   navigate("/success")
                    toast.success('Login Successfull');
                } else {
                    toast.error('Invalid Credentials');
                }
            } catch (error) {
                console.error('API Error:', error);
                toast.error('An error occurred while making the API call');
            }

           
        } else {
            toast.error("Invalid Credentials", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }
    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };
    return (

        <section className="loginbg-color">
            <div className="container p-5">
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <div className="login-bg">
                            <div className="row">
                                <div className="col-md-6">
                                    <img src="assets/login-logo.png" className='img-fluid w-100' style={{ height: "40rem" }} alt="login" />
                                </div>
                                <div className="col-md-6 login-align">
                                    <div className="sign-box">
                                        <h3>Admin Sign In</h3>
                                        <input className="form-control  user" type="text" placeholder="Username" value={username} onChange={(e) => {
                                            handleUsernameChange(e)
                                        }} />
                                        <input
                                            className="form-control pass"
                                            type={passwordVisible ? "text" : "password"}
                                            placeholder="Password"
                                            id="password-field"
                                            onChange={(e) => {
                                                handlePasswordChange(e)
                                            }}
                                        />
                                        <span
                                            onClick={togglePasswordVisibility}
                                            className={`fa field-icon ${passwordVisible ? "fa-eye-slash" : "fa-eye"}`}
                                        ></span>
                                       <button className="btn" onClick={handleSubmit}>Sign In</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}
export default Adminlogin;
