import React, { useEffect, useRef, useState } from "react";
import "./ProductKnoxQuad.css";
import Advanced from "../../../components/Advanced/Advanced";
import { toast } from "react-toastify";
import ProductKnoxQuadSeo from "./ProductKnoxQuadSeo";

const ProductKnoxQuad = () => {
    const [categoryId, setCategoryId] = useState("category003"); // Assuming categoryId is preset or dynamic
    const [banners, setBanners] = useState([{ id: 1, imgSrc: "", altText: "", file: null }]);
    const [faqs, setFaqs] = useState([{ id: 1, question: "Lorem ipsum dolor sit amet", answer: "" }]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editingFaq, setEditingFaq] = useState(null);
    const [activeTab, setActiveTab] = useState("web");

    const editorRef = useRef();

    // Fetch existing data for the category
    useEffect(() => {
        

        fetchData();
    }, []);
    const fetchData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_PROD_URL}/ColorBotCMSProductCategory/${categoryId}`);
            if (response.ok) {
                const result = await response.json();
                const { banners, faqs, editorContent } = result.data;

                setBanners(banners.map((banner, index) => ({
                    id: index + 1,
                    imgSrc: banner.imgSrc, // URL path
                    altText: banner.altText,
                    file: null // Since it's an existing URL, no file is set
                })));
                setFaqs(faqs);
               setTimeout(() => {
                editorRef?.current?.setContent(editorContent);
               }, 1000);
            } 
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const toggleTab = (tab) => {
        setActiveTab(tab);
    };

    const showModal = (faq) => {
        setEditingFaq(faq);
        setIsModalVisible(true);
    };

    // Handle FAQ change
    const handleFaqChange = (field, value) => {
        setEditingFaq({ ...editingFaq, [field]: value });
    };

    // Update the FAQ and close modal
    const handleSave = () => {
        const updatedFaqs = faqs.map(faq =>
            faq.id === editingFaq.id ? editingFaq : faq
        );
        setFaqs(updatedFaqs);
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    // Add more banner image
    const addMoreBanner = () => {
        const newBanner = { id: banners.length + 1, imgSrc: "", altText: "", file: null };
        setBanners([...banners, newBanner]);
    };

    // Handle banner image change
    const handleBannerChange = (index, type, value, file = null) => {
        const updatedBanners = banners.map((banner, i) =>
            i === index ? { ...banner, [type]: value, file: file || banner.file } : banner
        );
        setBanners(updatedBanners);
    };

    // Add more FAQ
    const addMoreFaq = () => {
        const newFaq = { id: faqs.length + 1, question: "", answer: "" };
        setFaqs([...faqs, newFaq]);
    };

    // Delete FAQ
    const deleteFaq = (index) => {
        const updatedFaqs = faqs.filter((faq, i) => i !== index);
        setFaqs(updatedFaqs);
    };

    // Delete Banner
    const deleteBanner = (index) => {
        const updatedBanners = banners.filter((_, i) => i !== index);
        setBanners(updatedBanners);
    };

    // API call function for creating or updating
    const handleSubmit = async () => {
        const formData = new FormData();

        // Append categoryId and editorContent
        formData.append('categoryId', categoryId);
        formData.append('editorContent', editorRef.current.getContent());

        // // Append banners
        // banners.forEach((banner, index) => {
        //     if (banner.file) {
        //         // If there's a file to upload
        //         formData.append(`banners[${index}][file]`, banner.file);
        //     } else {
        //         // If it's just an image URL
        //         formData.append(`banners[${index}][imgSrc]`, banner.imgSrc);
        //     }
        //     formData.append(`banners[${index}][altText]`, banner.altText);
        // });

        // Append FAQs as a JSON string
        // formData.append('faqs', JSON.stringify(faqs));

        // Send the API request
        try {
            const response = await fetch(`${process.env.REACT_APP_PROD_URL}/ColorBotCMSProductCategory`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${window.localStorage.getItem('token')}`
                },
                body: formData,
            });

            if (response.ok) {
                const result = await response.json();
                toast.success(result.message);
                fetchData()
            } else {
                toast.error('An error occurred while making the API call');
            }
        } catch (error) {
            console.error('API Error:', error);
            toast.error('An error occurred while making the API call');
        }
    };

    return (
        <div>
            <section className="bg-color">
                <div className="container p-4">
                    <div className="p-4">
                        <div className="col-lg-12 top-selector d-flex">
                            <div
                                className={`col-6 seo-sec ${activeTab === "seo" ? "active" : ""}`}
                                onClick={() => toggleTab("seo")}
                            >
                                SEO Settings
                            </div>
                            <div
                                className={`col-6 web-sec ${activeTab === "web" ? "active" : ""}`}
                                onClick={() => toggleTab("web")}
                            >
                                Web Settings
                            </div>
                        </div>
                    </div>

                    <div className="row p-4">
                        <div className="col-lg-12 web-settings-content">
                            {activeTab === "web" && (
                                <>
                                    <h4 className="top-banner">Product Knoxx Quad</h4>
                                    <div className="bannerWeb">
                                        {/* <div className="cards-content d-flex relative">
                                            <div className={banners.length < 6 ? `col-lg-10 banner-box` : `col-lg-12 banner-box`}>
                                                {banners.map((banner, index) => (
                                                    <div className="card-container" key={banner.id}>
                                                        <div
                                                            className="card card1"
                                                            onClick={() => {
                                                                document.getElementById(`banner-${index}`).click();
                                                            }}
                                                        >
                                                            <div className="card-body card-img">
                                                                <input
                                                                    id={`banner-${index}`}
                                                                    style={{ display: "none" }}
                                                                    type="file"
                                                                    onChange={(e) =>
                                                                        handleBannerChange(
                                                                            index,
                                                                            "imgSrc",
                                                                            URL.createObjectURL(e.target.files[0]),
                                                                            e.target.files[0] // Store the file object
                                                                        )
                                                                    }
                                                                />
                                                                {banner.imgSrc ? (
                                                                    <img
                                                                        src={banner.imgSrc}
                                                                        alt={banner.altText}
                                                                        className="categoryimage"
                                                                    />
                                                                ) : (
                                                                    <img src="Assets/images.svg" alt="placeholder" />
                                                                )}
                                                                <i
                                                                    className="fa fa-trash delete-icon"
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        deleteBanner(index);
                                                                    }}
                                                                    title="Delete"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="pargf">
                                                            <p className="tip">1440px X 518px</p>
                                                        </div>
                                                        <div className="card card2">
                                                            <div className="card-body card-text">
                                                                <input
                                                                    placeholder="Image Alt"
                                                                    value={banner.altText}
                                                                    onChange={(e) =>
                                                                        handleBannerChange(index, "altText", e.target.value)
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="col-lg-2 d-flex justify-content-center" style={{ alignItems: "center" }}>
                                                {banners.length < 6 && <button onClick={addMoreBanner} className="submit3">Add More</button>}
                                            </div>
                                        </div> */}

                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <div className="p-2 mt-4">
                                                    <Advanced editorRef={editorRef} />
                                                </div>
                                            </div>
                                        </div>

                                        {/* FAQ Section */}
                                        {/* <div className="col-lg-12 product-category d-flex justify-content-between">
                                            <div className="left-content">
                                                <h2>FAQ</h2>
                                            </div>
                                            <div className="ryt-content">
                                                <button className="btn btn-primary" onClick={addMoreFaq}>Add FAQ</button>
                                            </div>
                                        </div>

                                        {faqs.map((faq, index) => (
                                            <div className="col-lg-12 para d-flex justify-content-between" key={faq.id}>
                                                <span>{faq.question}</span>
                                                <div className="icons d-flex">
                                                    <i
                                                        className="fas fa-edit"
                                                        onClick={() => showModal(faq)}
                                                        style={{ cursor: "pointer", marginRight: "10px" }}
                                                    />
                                                    <i
                                                        className="fas fa-trash"
                                                        onClick={() => deleteFaq(index)}
                                                        style={{ cursor: "pointer" }}
                                                    />
                                                </div>
                                            </div>
                                        ))} */}

                                        <div className="bottom-btn justify-content-end align-content-end p-3">
                                            <button
                                                type="submit"
                                                className="submit1"
                                                onClick={() => {
                                                    setBanners([{ id: 1, imgSrc: "", altText: "", file: null }]);
                                                    setFaqs([{ id: 1, question: "Lorem ipsum dolor sit amet", answer: "" }]);
                                                    editorRef?.current?.setContent("");
                                                }}
                                            >
                                                Cancel
                                            </button>
                                            <button type="submit" className="submit2 m-1" style={{ color: "FFFFFF" }} onClick={()=>{
                                                handleSubmit()
                                            }}>Apply</button>
                                        </div>
                                    </div>
                                    <div
                                            className={`faqmodal modal fade ${isModalVisible ? "show" : ""}`}
                                            style={{ display: isModalVisible ? "block" : "none", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                                            tabIndex="-1"
                                        >
                                            <div className="modal-dialog">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title">Frequently Asked Questions</h5>
                                                        <button type="button" className="btn-close" onClick={handleCancel}></button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <div className="form-group">
                                                            <label htmlFor="faq-question">Question</label>
                                                            <input
                                                                id="faq-question"
                                                                type="text"
                                                                className="form-control"
                                                                value={editingFaq?.question || ""}
                                                                onChange={(e) => handleFaqChange("question", e.target.value)}
                                                            />
                                                        </div>
                                                        <div className="form-group mt-3">
                                                            <label htmlFor="faq-answer">Answer</label>
                                                            <textarea
                                                                id="faq-answer"
                                                                className="form-control"
                                                                rows="3"
                                                                value={editingFaq?.answer || ""}
                                                                onChange={(e) => handleFaqChange("answer", e.target.value)}
                                                            ></textarea>
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" onClick={handleCancel}>
                                                            Cancel
                                                        </button>
                                                        <button type="button" className="btn btn-primary" onClick={handleSave}>
                                                            Save changes
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {isModalVisible && (
                                            <div
                                                className="modal-backdrop fade show"
                                                onClick={handleCancel}
                                                style={{ cursor: "pointer" }}
                                            ></div>
                                        )}
                                </>
                            )}
                            {activeTab === "seo" && <ProductKnoxQuadSeo/>}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default ProductKnoxQuad;
