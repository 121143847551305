import React from 'react'
import { useNavigate } from 'react-router-dom';
import "./Loginsuccess.css";
export const Login_success = (props) => {
    const navigate = useNavigate();
    const handleOk = () => {
        if (window.localStorage.getItem("isLoggedIn")) {
            props.setisLoggedIn(true)
            navigate("/Blog");
        }else{
            navigate("/");
            window.localStorage.clear();
        }
    }
    return (
        <div>
            <section className="loginbg-color">
                <div className="container">
                    <div className="row justify-content-center loginalign h-100">
                        <div className="col-lg-5">
                            <div className="success-box">
                                <div className="success-img">
                                    <img src="assets/Sucess-icon.png" alt="success" />
                                </div>

                                <div className="success-txt">
                                    <h2>Sign in successful</h2>
                                    <p>Welcome to Colorbot</p>
                                </div>

                                <div className="success-btn">
                                    <button className="btn" onClick={handleOk}>Ok</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default Login_success;
