import React from 'react'
import "./Accessories.css";
import Advanced from '../../../components/Advanced/Advanced';

const Accessories = () => {
    return (
        <div>
            <section className="bg-color">
                <div className="container p-4">
                    <div className="p-4">
                        <div className="col-lg-12 top-selector d-flex ">
                            <div className="col-6 seo-sec">
                                <p> SEO Settings </p>
                            </div>
                            <div className="col-6 web-sec">
                                <p>Web Settings</p>
                            </div>
                        </div>
                    </div>

                    <div className="row p-4">
                        <div className="col-lg-12 web-settings-content">

                        <div className='col-lg-12'>
                                <div className="form-group">
                                    {/* <label for="exampleFormControlInput1" className="large-label">Post Content</label> */}
                                    <div className='p-2 mt-4'>
                                        <Advanced />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 product-category d-flex space-between ">
                                <div className="left-content">
                                    <h2>FAQ</h2>
                                </div>
                                <div className="ryt-content">
                                    <button className="FAQ">Add FAQ</button>
                                </div>
                            </div>



                            <div className="col-lg-12 para d-flex">
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse, voluptatem et. Doloremque
                                    architecto obcaecati blanditiis?</p>
                                <div className="icons d-flex">
                                    <i class="fas fa-edit"></i>
                                    <i class="fa fa-trash" aria-hidden="true"></i>
                                </div>
                            </div>


                            <div className="col-lg-12 para d-flex">
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse, voluptatem et. Doloremque
                                    architecto obcaecati blanditiis?</p>
                                <div className="icons d-flex">
                                    <i class="fas fa-edit"></i>
                                    <i class="fa fa-trash" aria-hidden="true"></i>
                                </div>
                            </div>


                            <div className="col-lg-12 para d-flex">
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse, voluptatem et. Doloremque
                                    architecto obcaecati blanditiis?</p>
                                <div className="icons d-flex">
                                    <i class="fas fa-edit"></i>
                                    <i class="fa fa-trash" aria-hidden="true"></i>
                                </div>
                            </div>


                            <div className="col-lg-12 para d-flex">
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse, voluptatem et. Doloremque
                                    architecto obcaecati blanditiis?</p>
                                <div className="icons d-flex">
                                    <i class="fas fa-edit"></i>
                                    <i class="fa fa-trash" aria-hidden="true"></i>
                                </div>
                            </div>


                            <div className="col-lg-12 para d-flex">
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse, voluptatem et. Doloremque
                                    architecto obcaecati blanditiis?</p>
                                <div className="icons d-flex">
                                    <i class="fas fa-edit"></i>
                                    <i class="fa fa-trash" aria-hidden="true"></i>
                                </div>
                            </div>



                            <div className="bottom-btn justify-content-end align-content-end p-3">
                                <button type="submit" className="submit1">Cancel</button>
                                <button type="submit" className="submit2 m-1 " style={{ color: "FFFFFF" }}>Apply</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default Accessories;
