import React, { useEffect } from 'react'
import "./Navbar.css";
import { NavLink, Navigate, Route, Routes } from 'react-router-dom';
import Blog from "../../pages/Blogs/Blog"
import AddNewBlog from '../../pages/AddNewBlog/AddNewBlog';
import Home from "../../pages/Pages/Home/WEB/HomeWeb";
import ProductCategory from "../../pages/Pages/ProductCategory/ProductCategory"
import Accessories from "../../pages/Pages/Accessories/Accessories"
import Privacy_policy from "../../pages/Pages/Privacy_policy/Privacy_policy"
import Terms_condition from "../../pages/Pages/Terms/Terms_condition"
import FAQ from "../../pages/Pages/FAQ/FAQ"
import Service from "../../pages/Pages/ServicesAndPolicies/Service"
import Return from "../../pages/Pages/Return_policy/Return"
import Blog_desc from '../../pages/Pages/Blog_desc/Blog_desc';
import EditNewBlog from '../../pages/EditNewBlog/EditNewBlog';
import ProductKnoxQuad from '../../pages/Pages/ProductKnoxQuad/ProductKnoxQuad';

const Navbar = (props) => {
    

    return (
        <>
            <div id="content" className={props.active  ? "top-navbarfull" : "top-navbar"}>
           
                <nav className="navbar navbar-expand-lg navbar-light">
                    <div className="container-fluid">

                        <div id="sidebarCollapse" className="btn" onClick={() => {
                            props.handlesidebar()
                        }}>
                            <img src="assets/threedot.svg" alt="" width="30px" />
                            <a href="javascript:void(0)"></a>
                        </div>
                        <ul className="nav navbar-nav ml-auto">                          
                        <li>
                            <NavLink to="https://colorbot.in/">Visit Website</NavLink>
                        </li>                                                      
                        </ul>
                    </div>
                </nav>

                <Routes>
                {props.isLoggedIn? <Route exact path="/" element={<Blog />}  />:<Route exact path="/" element={<Navigate to="/" />} />}
                {props.isLoggedIn? <Route exact path="/Blog" element={<Blog />}  />:<Route exact path="/Blog" element={<Navigate to="/" />} />}
                {props.isLoggedIn?<Route exact path="/AddNewBlog" element={<AddNewBlog/>}/>:<Route exact path="/AddNewBlog" element={<Navigate to="/" />}/>}
                {props.isLoggedIn?<Route exact path="/EditNewBlog/:id" element={<EditNewBlog/>}/>:<Route exact path="/EditNewBlog/:id" element={<Navigate to="/" />}/>}
                {props.isLoggedIn?<Route exact path="/Home" element={<Home/>}/>:<Route exact path="/Home" element={<Navigate to="/" />}/>}
                {props.isLoggedIn?<Route exact path="/ProductCategory" element={<ProductCategory/>}/>:<Route exact path="/ProductCategory" element={<Navigate to="/" />}/>}
                {props.isLoggedIn?<Route exact path="/ProductKnoxxQuad" element={<ProductKnoxQuad/>}/>:<Route exact path="/ProductKnoxxQuad" element={<Navigate to="/" />}/>}
                {props.isLoggedIn?<Route exact path="/Accessories" element={<Accessories/>}/>:<Route exact path="/Accessories" element={<Navigate to="/" />}/>}
                {props.isLoggedIn?<Route exact path="/Privacy_policy" element={<Privacy_policy/>}/>:<Route exact path="/Privacy_policy" element={<Navigate to="/" />}/>}
                {props.isLoggedIn?<Route exact path="/Terms_condition" element={<Terms_condition/>}/>:<Route exact path="/Terms_condition" element={<Navigate to="/" />}/>}
                {props.isLoggedIn?<Route exact path="/FAQ" element={<FAQ/>}/>:<Route exact path="/FAQ" element={<Navigate to="/" />}/>}
                {props.isLoggedIn?<Route exact path="/Service" element={<Service/>}/>:<Route exact path="/Service" element={<Navigate to="/" />}/>}
                {props.isLoggedIn?<Route exact path="/Return" element={<Return/>}/>:<Route exact path="/Return" element={<Navigate to="/" />}/>}
                {props.isLoggedIn?<Route exact path="/Return" element={<Blog_desc/>}/>:<Route exact path="/Blog_Desc" element={<Navigate to="/" />}/>}
                </Routes>
            </div>
        </>
    );
}
export default Navbar;
