import React, { useEffect, useRef, useState } from "react";
import "./HomeWeb.css";
import Advanced from "../../../../components/Advanced/Advanced";
import { toast } from "react-toastify";
import HomeSeo from "../SEO/HomeSeo";

const HomeWeb = () => {
    const [categoryId, setCategoryId] = useState("home001"); // Assuming categoryId is preset or dynamic
    const [banners, setBanners] = useState([{ id: 1, imgSrc: "", altText: "", file: null }]);
    
   
   
    const [activeTab, setActiveTab] = useState("web");


    // Fetch existing data for the category
    useEffect(() => {
        

        fetchData();
    }, []);
    const fetchData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_PROD_URL}/ColorBotCMSProductCategory/${categoryId}`);
            if (response.ok) {
                const result = await response.json();
                const { banners } = result.data;

                setBanners(banners.map((banner, index) => ({
                    id: index + 1,
                    imgSrc: banner.imgSrc, // URL path
                    altText: banner.altText,
                    file: null // Since it's an existing URL, no file is set
                })));
               
            } 
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const toggleTab = (tab) => {
        setActiveTab(tab);
    };


   


  
    // Add more banner image
    const addMoreBanner = () => {
        const newBanner = { id: banners.length + 1, imgSrc: "", altText: "", file: null };
        setBanners([...banners, newBanner]);
    };

    // Handle banner image change
    const handleBannerChange = (index, type, value, file = null) => {
        const updatedBanners = banners.map((banner, i) =>
            i === index ? { ...banner, [type]: value, file: file || banner.file } : banner
        );
        setBanners(updatedBanners);
    };


   

    // API call function for creating or updating
    const handleSubmit = async () => {
        const formData = new FormData();

        // Append categoryId and editorContent
        formData.append('categoryId', categoryId);
        formData.append('editorContent', "d");

        // Append banners
        banners.forEach((banner, index) => {
            if (banner.file) {
                // If there's a file to upload
                formData.append(`banners[${index}][file]`, banner.file);
            } else {
                // If it's just an image URL
                formData.append(`banners[${index}][imgSrc]`, banner.imgSrc);
            }
            formData.append(`banners[${index}][altText]`, banner.altText);
        });

   

        // Send the API request
        try {
            const response = await fetch(`${process.env.REACT_APP_PROD_URL}/ColorBotCMSProductCategory`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${window.localStorage.getItem('token')}`
                },
                body: formData,
            });

            if (response.ok) {
                const result = await response.json();
                toast.success("Home Web Settings Updated Successfully");
                fetchData()
            } else {
                toast.error('An error occurred while making the API call');
            }
        } catch (error) {
            console.error('API Error:', error);
            toast.error('An error occurred while making the API call');
        }
    };

     // Delete Banner
     const deleteBanner = (index) => {
        const updatedBanners = banners.filter((_, i) => i !== index);
        setBanners(updatedBanners);
    };
    return (
        <div>
            <section className="bg-color">
                <div className="container p-4">
                    <div className="p-4">
                        <div className="col-lg-12 top-selector d-flex">
                            <div
                                className={`col-6 seo-sec ${activeTab === "seo" ? "active" : ""}`}
                                onClick={() => toggleTab("seo")}
                            >
                                SEO Settings
                            </div>
                            <div
                                className={`col-6 web-sec ${activeTab === "web" ? "active" : ""}`}
                                onClick={() => toggleTab("web")}
                            >
                                Web Settings
                            </div>
                        </div>
                    </div>

                    <div className="row p-4">
                        <div className="col-lg-12 web-settings-content">
                            {activeTab === "web" && (
                                <>
                                    <h4 className="top-banner">Banners</h4>
                                    <div className="bannerWeb">
                                        <div className="cards-content d-flex relative">
                                            <div className={banners.length < 6 ? `col-lg-10 banner-box` : `col-lg-12 banner-box`}>
                                                {banners.map((banner, index) => (
                                                    <div className="card-container" key={banner.id}>
                                                        <div
                                                            className="card card1"
                                                            onClick={() => {
                                                                document.getElementById(`banner-${index}`).click();
                                                            }}
                                                        >
                                                            <div className="card-body card-img">
                                                                <input
                                                                    id={`banner-${index}`}
                                                                    style={{ display: "none" }}
                                                                    type="file"
                                                                    onChange={(e) =>
                                                                        handleBannerChange(
                                                                            index,
                                                                            "imgSrc",
                                                                            URL.createObjectURL(e.target.files[0]),
                                                                            e.target.files[0] // Store the file object
                                                                        )
                                                                    }
                                                                />
                                                                {banner.imgSrc ? (
                                                                    <img
                                                                        src={banner.imgSrc}
                                                                        alt={banner.altText}
                                                                        className="categoryimage"
                                                                    />
                                                                ) : (
                                                                    <img src="Assets/images.svg" alt="placeholder" />
                                                                )}
                                                                <i
                                                                    className="fa fa-trash delete-icon"
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        deleteBanner(index);
                                                                    }}
                                                                    title="Delete"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="pargf">
                                                            <p className="tip">1440px X 518px</p>
                                                        </div>
                                                        <div className="card card2">
                                                            <div className="card-body card-text">
                                                                <input
                                                                    placeholder="Image Alt"
                                                                    value={banner.altText}
                                                                    onChange={(e) =>
                                                                        handleBannerChange(index, "altText", e.target.value)
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="col-lg-2 d-flex justify-content-center" style={{ alignItems: "center" }}>
                                                {banners.length < 6 && <button onClick={addMoreBanner} className="submit3">Add More</button>}
                                            </div>
                                        </div>


                                      
                                       

                                        <div className="bottom-btn justify-content-end align-content-end p-3">
                                            <button
                                                type="submit"
                                                className="submit1"
                                                onClick={() => {
                                                    setBanners([{ id: 1, imgSrc: "", altText: "", file: null }]);
                                                 
                                                }}
                                            >
                                                Cancel
                                            </button>
                                            <button type="submit" className="submit2 m-1" style={{ color: "FFFFFF" }} onClick={()=>{
                                                handleSubmit()
                                            }}>Apply</button>
                                        </div>
                                    </div>
                                    
                                </>
                            )}
                            {activeTab === "seo" && <HomeSeo/>}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default HomeWeb;
