import React from 'react'
import "./Blog_desc.css";
const Blog_desc = () => {
    return (
        <div>
            <section className="bg-color">
                <div className="container p-4">
                    <div className="row">
                        <div className="p-4">
                            <div className="col-lg-12 top-selector d-flex ">
                                <div className="col-6 seo-sec">
                                    <p> SEO Settings </p>
                                </div>
                                <div className="col-6 web-sec">
                                    <p>Web Settings</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-12 mt-4 d-flex row-content-top" style={{ background: "#F6F7FF" }}>
                            <div className="row-content d-flex">
                                <span className="s-n">1</span>
                                <img src="../assets/fan.svg" className="image" />
                                <p className="product-name">Colorbot Helix BLDC ceiling fan<br />
                                    <span className="tip mt-2 "> Jan 25, 2024</span>
                                </p>
                            </div>
                            <div className="icons d-flex">
                                <button>SEO</button>
                            </div>
                        </div>
                        <div className="col-lg-12 d-flex row-content-top" style={{ background: "white" }}>
                            <div className="row-content d-flex">
                                <span className="s-n">2</span>
                                <img src="../assets/fan.svg" className="image" />
                                <p className="product-name">Colorbot Helix BLDC ceiling fan<br />
                                    <span className="tip mt-2 "> Jan 25, 2024</span>
                                </p>
                            </div>

                            <div className="icons d-flex">
                                <button>SEO</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 d-flex row-content-top" style={{ background: "#F6F7FF" }}>
                        <div className="row-content d-flex">
                            <span className="s-n">3</span>
                            <img src="../assets/fan.svg" className="image" />
                            <p className="product-name">Colorbot Helix BLDC ceiling fan<br />
                                <span className="tip mt-2 "> Jan 25, 2024</span>
                            </p>
                        </div>
                        <div className="icons d-flex">
                            <button>SEO</button>
                        </div>
                    </div>
                    <div className="col-lg-12 d-flex row-content-top" style={{ background: "white" }}>
                        <div className="row-content d-flex">
                            <span className="s-n">4</span>
                            <img src="../assets/fan.svg" className="image" />
                            <p className="product-name">Colorbot Helix BLDC ceiling fan<br />
                                <span className="tip mt-2 "> Jan 25, 2024</span>
                            </p>
                        </div>
                        <div className="icons d-flex">
                            <button>SEO</button>
                        </div>
                    </div>
                    <div className="col-lg-12 d-flex row-content-top" style={{ background: "#F6F7FF" }}>
                        <div className="row-content d-flex">
                            <span className="s-n">5</span>
                            <img src="../assets/fan.svg" className="image" />
                            <p className="product-name">Colorbot Helix BLDC ceiling fan<br />
                                <span className="tip mt-2 "> Jan 25, 2024</span>
                            </p>
                        </div>
                        <div className="icons d-flex">
                            <button>SEO</button>
                        </div>
                    </div>
                    <div className="col-lg-12 d-flex row-content-top" style={{ background: "white" }}>
                        <div className="row-content d-flex">
                            <span className="s-n">6</span>
                            <img src="../assets/fan.svg" class="image" />
                            <p className="product-name">Colorbot Helix BLDC ceiling fan<br />
                                <span className="tip mt-2 "> Jan 25, 2024</span>
                            </p>
                        </div>
                        <div className="icons d-flex">
                            <button>SEO</button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default Blog_desc;
