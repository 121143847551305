import React from 'react'
import "./Service.css";
import Advanced from '../../../components/Advanced/Advanced';

const Service = () => {
    return (
        <div>
            <section className="bg-color">
                <div className="container p-4">
                    <div className=" p-4">
                        <div className="col-lg-12 top-selector d-flex ">
                            <div className="col-6 seo-sec">
                                <p> SEO Settings </p>
                            </div>
                            <div className="col-6 web-sec">
                                <p>Web Settings</p>
                            </div>
                        </div>
                    </div>
                    <div className="row p-4">
                        <div className="service">
                            <div className="col-lg-12">
                                <div className=" row admin-blog-heading">
                                    <div className="col m-2">Distributor Policy</div>
                                    <div className="col m-2">Shipping policy</div>
                                    <div className="col m-2">Warranty policy</div>
                                    <div className="col m-2">Payment Security</div>
                                    <div className="col m-2">Customer grievance</div>

                                </div>
                            </div>
                            <div className='p-2 mt-4'>
                                <Advanced />
                            </div>
                            <div className="bottom-btn justify-content-end align-content-end p-3">
                                <button type="submit" clasName="submit1">Cancel</button>
                                <button type="submit" className="submit2 m-1 " style={{ color: "FFFFFF" }}>Apply</button>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Service;
