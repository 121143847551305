import React, { useEffect, useState } from 'react';
import './ProductKnoxQuadSeo.css';
import './ProductKnoxQuad.css'; // Assuming global styles are in this file
import { toast } from 'react-toastify';

const ProductKnoxQuadSeo = () => {
    const [twitterEnabled, setTwitterEnabled] = useState(false);
    const [seoData, setSeoData] = useState({
        urlSlug: '',
        titleTag: '',
        metaDescription: '',
        markupName: '',
        jsonLdMarkup: '',
        ogTitle: '',
        ogDescription: '',
    });
    const [ogImageFile, setOgImageFile] = useState(null);
    const [ogImagePreview, setOgImagePreview] = useState(null);

    // Handle Twitter toggle
    const handleTwitterToggle = () => {
        setTwitterEnabled(!twitterEnabled);
    };

    const fetchSeoData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_PROD_URL}/seo-settings/seo003`);
            if (response.ok) {
                const result = await response.json();
                const data = result.data;
                setSeoData({
                    urlSlug: data.urlSlug,
                    titleTag: data.titleTag,
                    metaDescription: data.metaDescription,
                    markupName: data.markupName,
                    jsonLdMarkup: data?.jsonLdMarkup,
                    ogTitle: data.ogTitle,
                    ogDescription: data.ogDescription,
                });
                setTwitterEnabled(data.twitterEnabled);
                if (data.ogImage) {
                    setOgImagePreview(data.ogImage);
                }
            }
        } catch (error) {
          
        }

    };

    useEffect(() => {
        fetchSeoData();
    },[])
    // Handle form input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSeoData({
            ...seoData,
            [name]: value,
        });
    };

    // Handle Open Graph image selection
    const handleImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setOgImageFile(event.target.files[0]);
            setOgImagePreview(URL.createObjectURL(event.target.files[0]));
        }
    };

    // Handle form submission
    const handleSubmit = async () => {
        
        const formData = new FormData();

        // Append all text fields
        formData.append("seoId", "seo003");
        formData.append('urlSlug', seoData.urlSlug);
        formData.append('titleTag', seoData.titleTag);
        formData.append('metaDescription', seoData.metaDescription);
        formData.append('markupName', seoData.markupName);
        formData.append('jsonLdMarkup', seoData.jsonLdMarkup );
        formData.append('ogTitle', seoData.ogTitle);
        formData.append('ogDescription', seoData.ogDescription);
        formData.append('twitterEnabled', twitterEnabled);

        // Append og:image file if uploaded
        if (ogImageFile) {
            formData.append('ogImage', ogImageFile);
        }

        // API Call
        try {
            const response = await fetch(`${process.env.REACT_APP_PROD_URL}/seo-settings`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${window.localStorage.getItem('token')}`
                },
                body: formData,
            });

            if (response.ok) {
                const result = await response.json();
                toast.success('SEO Settings successfully saved!');
                fetchSeoData()
            } else {
                toast.error('Failed to save SEO Settings');
            }
        } catch (error) {
            toast.error('Error submitting SEO data:');
        }
    };

    return (
        <div className="seo-section">
            <h2 className="seo-heading">SEO Settings</h2>

           
                {/* URL/Canonical Tag */}
                <div className="seo-form-group">
                    <label className="seo-label">URL SLUG/Canonical Tag</label>
                    <input
                        type="text"
                        className="seo-input"
                        name="urlSlug"
                        value={seoData.urlSlug}
                        onChange={handleInputChange}
                        placeholder="Enter URL SLUG/Canonical Tag"
                    />
                </div>

                {/* Title Tag */}
                <div className="seo-form-group">
                    <label className="seo-label">Title Tag</label>
                    <input
                        type="text"
                        className="seo-input"
                        name="titleTag"
                        value={seoData.titleTag}
                        onChange={handleInputChange}
                        placeholder="Enter Title Tag"
                    />
                </div>

                {/* Meta Description */}
                <div className="seo-form-group">
                    <label className="seo-label">Meta Description</label>
                    <textarea
                        className="seo-textarea"
                        name="metaDescription"
                        value={seoData.metaDescription}
                        onChange={handleInputChange}
                        placeholder="Enter Meta Description"
                        rows="4"
                    />
                </div>

                {/* Add New Markup */}
                <div className="seo-form-group">
                    <h3 className="seo-subheading">Add New Markup</h3>
                    {/* <label className="seo-label">Name</label>
                    <input
                        type="text"
                        className="seo-input"
                        name="markupName"
                        value={seoData.markupName}
                        onChange={handleInputChange}
                        placeholder="Enter Markup Name"
                    /> */}
                </div>

                <div className="seo-form-group">
                    <label className="seo-label">Write your markup in JSON-LD format</label>
                    <textarea
                        className="seo-textarea"
                        name="jsonLdMarkup"
                        value={seoData.jsonLdMarkup}
                        onChange={handleInputChange}
                        placeholder="Enter JSON-LD Markup"
                        rows="4"
                    />
                </div>

                {/* Social Share */}
                <div className="seo-form-group">
                    <h3 className="seo-subheading">Social Share</h3>
                    <p className="seo-description">
                        Open graph (og) tags are used by social networks like Facebook & Pinterest to display text and an image when this page is shared.
                    </p>

                    {/* <div className="seo-social-toggle">
                        <label className="seo-label">Twitter</label>
                        <input
                            type="checkbox"
                            checked={twitterEnabled}
                            onChange={handleTwitterToggle}
                            className="seo-toggle"
                        />
                    </div> */}

                    {/* Open Graph Image Upload */}
                    <div className="seo-form-group">
                        <label className="seo-label">og:image</label>
                        <div className="seo-ogimage-container">
                            {ogImagePreview ? (
                                <img src={ogImagePreview} alt="OG Preview" className="seo-ogimage-preview" />
                            ) : (
                                <div className="seo-placeholder-image"><img src="Assets/images.svg" alt="placeholder" /></div>
                            )}
                            <input type="file" className="seo-file-input" onChange={handleImageChange} />
                        </div>
                        <p className="seo-ogimage-dimensions">169px x 169px</p>
                    </div>

                    {/* Open Graph Title */}
                    <div className="seo-form-group">
                        <label className="seo-label">og:title</label>
                        <input
                            type="text"
                            className="seo-input"
                            name="ogTitle"
                            value={seoData.ogTitle}
                            onChange={handleInputChange}
                            placeholder="Enter Open Graph Title"
                        />
                    </div>

                    {/* Open Graph Description */}
                    <div className="seo-form-group">
                        <label className="seo-label">og:description</label>
                        <textarea
                            className="seo-textarea"
                            name="ogDescription"
                            value={seoData.ogDescription}
                            onChange={handleInputChange}
                            placeholder="Enter Open Graph Description"
                            rows="4"
                        />
                    </div>

                    {/* Submit Button */}
                    <div className="bottom-btn justify-content-end align-content-end p-3">
                        <button
                            type="button"
                            className="submit1"
                            onClick={() => {
                                setSeoData({
                                    urlSlug: '',
                                    titleTag: '',
                                    metaDescription: '',
                                    markupName: '',
                                    jsonLdMarkup: '',
                                    ogTitle: '',
                                    ogDescription: ''
                                });
                                setOgImagePreview(null);
                            }}
                        >
                            Cancel
                        </button>
                        <button type="submit" className="submit2 m-1" style={{ color: "FFFFFF" }} onClick={()=>{
                            handleSubmit()
                        }}>Apply</button>
                    </div>
                </div>
        </div>
    );
};

export default ProductKnoxQuadSeo;
