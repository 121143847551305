import React from 'react'
import "./Return.css";
import Advanced from '../../../components/Advanced/Advanced';

const Return = () => {
  return (
    <div>
        <div>
       <section className="bg-color">
        <div className="container p-4">
            <div className=" p-4">
                <div className="col-lg-12 top-selector d-flex ">
                    <div className="col-6 seo-sec">
                        <p> SEO Settings </p>
                    </div>
                    <div className="col-6 web-sec">
                        <p>Web Settings</p>
                    </div>
                </div>
            </div>
            <div className="row p-4">
                <div className="form-div-seo">
                 
                <div className='p-2 mt-4'>
                                <Advanced />
                            </div>
                        <div className="bottom-btn justify-content-end align-content-end p-3">
                            <button type="submit" className="submit1">Cancel</button>
                            <button type="submit" className="submit2 m-1 " style={{color: "FFFFFF"}}>Apply</button>
                        </div>
               
                </div>
            </div>
        </div>
    </section>
    </div>
    </div>
  )
}
export default Return;
