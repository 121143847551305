import React, { useEffect, useState } from 'react'
import Adminlogin from './pages/Admin/Adminlogin';
import Navbar from "./components/Navbar/Navbar"
import Sidebar from './components/Sidebar/Sidebar';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Loginsuccess from "./pages/Login/Loginsuccess";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./App.css";
function App() {
  const [active, setactive] = useState(false)
  const [isLoggedIn, setisLoggedIn] = useState(false)
  const handlesidebar = () =>{
    setactive(!active)
  }
useEffect(() =>{
  if(window.localStorage.getItem("isLoggedIn")){
    setisLoggedIn(true)
  }else{
    setisLoggedIn(false)
  }
}, [isLoggedIn])

  return (
    <div className="App">
    
    <BrowserRouter>
      <Routes>
        {!isLoggedIn ? <Route exact path='/' element={<Adminlogin setisLoggedIn={setisLoggedIn}/>}></Route> : <Route path="/success" element={<Navigate to="/Blog" />} />}
        {!isLoggedIn ? <Route exact path="/success" element={<Loginsuccess setisLoggedIn={setisLoggedIn}/>}></Route> : <Route path="/success" element={<Navigate to="/Blog" />}/>}
      </Routes>

      {isLoggedIn ? <div className="wrapper">
        <Sidebar active={active} setisLoggedIn={setisLoggedIn} />
        <Navbar handlesidebar={handlesidebar} active={active} isLoggedIn={isLoggedIn} />
      </div>:" "}
    </BrowserRouter>  
    <ToastContainer />    
     </div>
  )  
  }
export default App;
