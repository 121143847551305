import React, { useEffect, useState } from 'react'
import "./Sidebar.css";
import { NavLink, useNavigate } from 'react-router-dom';
import Collapse from 'react-bootstrap/Collapse';
import { IoMdArrowDropdown } from "react-icons/io";

const Sidebar = (props) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(()=>{
    setOpen(window.location.pathname === "/ProductCategory")
  },[])
  

  return (
    <div>
      <nav id="sidebar" className={props.active ? "active" : ""}>
        <div className={props.active ? "sidebar-headeractive" : "sidebar-header"}>
          {props.active ? <NavLink to="/Blog" className={({ isActive }) => isActive ? "active" : ""}><img src="assets/closelogo.png" alt=" " width="50px" className="active" /></NavLink> : <NavLink to="/blog" className="logo"><img src="assets/logo.svg" alt=" " width="150px" /></NavLink>}
        </div>
        <hr style={{ height: "2px", backgroundColor: "#19289F", margin: "0.7rem 0" }} />

        <ul className="list-unstyled side-link " >
          <li >
            <NavLink to="/Blog" className={({ isActive }) => isActive ? "active" : ""}> <img src="assets/Blogs.svg" alt=" " width="25.39px" height="25px" /> {props.active ? "" : <a href="javascript:void(0)">Blogs</a>}</NavLink>
          </li>
          <li>
            <NavLink to="/AddNewBlog" className={({ isActive }) => isActive ? "active" : ""}> <img src="assets/AddNewBlog.svg" alt="" width="25.39px" height="25px" /> {props.active ? "" : <a href="javascript:void(0)">Add New Blog</a>}</NavLink>
          </li><hr />
          <li className='nav-item'>
            <button onClick={() => setOpen(!open)}
              aria-controls="example-collapse-text"
              aria-expanded={open}
              className="pages-link filter-pop m-1" variant="transparent" style={{ fontSize: "1.2rem", paddingLeft: "1rem" }}
            >
              <IoMdArrowDropdown /> Pages
            </button>
          </li>
          <Collapse in={open}>

            <div id='' className='nav-iiner-item'>
              <ul>
                <li>
                  <NavLink to="/Home" className={({ isActive }) => isActive ? "active" : ""}>{props.active ? "" : <a href="javascript:void(0)">Home</a>}</NavLink>
                </li>

                <li>
                  <NavLink to="/ProductCategory" className={({ isActive }) => isActive ? "active" : ""}>{props.active ? "" : <a href="javascript:void(0)">Product Category</a>}</NavLink>
                </li>

                <li>
                  <NavLink to="/ProductHelix" className={({ isActive }) => isActive ? "active" : ""}>{props.active ? "" : <a href="javascript:void(0)">Product Helix</a>}
                  </NavLink>
                </li>

                <li>
                  <NavLink to="/ProductKnoxx" className={({ isActive }) => isActive ? "active" : ""}>{props.active ? "" : <a href="javascript:void(0)">Product Knoxx</a>}
                  </NavLink>
                </li>

                <li>
                  <NavLink to="/ProductStella" className={({ isActive }) => isActive ? "active" : ""}>{props.active ? "" : <a href="javascript:void(0)">Product Stella</a>}
                  </NavLink>
                </li>

                <li>
                  <NavLink to="/ProductKnoxxQuad" className={({ isActive }) => isActive ? "active" : ""}>{props.active ? "" : <a href="javascript:void(0)">Product Knoxx Quad</a>}
                  </NavLink>
                </li>

                <li>
                  <NavLink to="/ProductStellaQuad" className={({ isActive }) => isActive ? "active" : ""}>{props.active ? "" : <a href="javascript:void(0)">Product Stella Quad</a>}
                  </NavLink>
                </li>

                <li>
                  <NavLink to="/ProductPrizma" className={({ isActive }) => isActive ? "active" : ""}>{props.active ? "" : <a href="javascript:void(0)">Product Prizma</a>}
                  </NavLink>
                </li>

                <li>
                  <NavLink to="/Dark" className={({ isActive }) => isActive ? "active" : ""}>{props.active ? "" : <a href="javascript:void(0)">Dark</a>}
                  </NavLink>
                </li>

                <li>
                  <NavLink to="/Accessories" className={({ isActive }) => isActive ? "active" : ""}>{props.active ? "" : <a href="javascript:void(0)">Accessories</a>}
                  </NavLink>
                </li>

                <li>
                  <NavLink to="/Privacy_policy" className={({ isActive }) => isActive ? "active" : ""}>{props.active ? "" : <a href="javascript:void(0)">Privacy Policy</a>}
                  </NavLink>
                </li>

                <li>
                  <NavLink to="/Terms_condition" className={({ isActive }) => isActive ? "active" : ""}>{props.active ? "" : <a href="javascript:void(0)">Terms Conditions</a>}
                  </NavLink>
                </li>

                <li>
                  <NavLink to="/Return" className={({ isActive }) => isActive ? "active" : ""}>{props.active ? "" : <a href="javascript:void(0)">Return-policy</a>}
                  </NavLink>
                </li>

                <li>
                  <NavLink to="/FAQ" className={({ isActive }) => isActive ? "active" : ""}>{props.active ? "" : <a href="javascript:void(0)">FAQ</a>}
                  </NavLink>
                </li>

                <li>
                  <NavLink to="/Service" className={({ isActive }) => isActive ? "active" : ""}>{props.active ? "" : <a href="javascript:void(0)">Services and Policies</a>}
                  </NavLink>
                </li>

                <li>
                  <NavLink to="/Technology" className={({ isActive }) => isActive ? "active" : ""}>{props.active ? "" : <a href="javascript:void(0)">Technology</a>}
                  </NavLink>
                </li>

                <li>
                  <NavLink to="/About" className={({ isActive }) => isActive ? "active" : ""}>{props.active ? "" : <a href="javascript:void(0)">About</a>}
                  </NavLink>
                </li>

                <li>
                  <NavLink to="/Blog" className={({ isActive }) => isActive ? "active" : ""}>{props.active ? "" : <a href="javascript:void(0)">Blog</a>}
                  </NavLink>
                </li>

                <li>
                  <NavLink to="/Blog_desc" className={({ isActive }) => isActive ? "active" : ""}>{props.active ? "" : <a href="javascript:void(0)">Blog-desc</a>}
                  </NavLink>
                </li>

                <li>
                  <NavLink to="/Sitemap" className={({ isActive }) => isActive ? "active" : ""}>{props.active ? "" : <a href="javascript:void(0)">Sitemap</a>}
                  </NavLink>
                </li>

                <li>
                  <NavLink to="/Contact" className={({ isActive }) => isActive ? "active" : ""}>{props.active ? "" : <a href="javascript:void(0)">Contact</a>}
                  </NavLink>
                </li>

                <li>
                  <NavLink to="/Register-Warranty" className={({ isActive }) => isActive ? "active" : ""}>{props.active ? "" : <a href="javascript:void(0)">Register-Warranty</a>}</NavLink>
                </li>

                <li>
                  <NavLink to="/Track-order" className={({ isActive }) => isActive ? "active" : ""}>{props.active ? "" : <a href="javascript:void(0)">Track-order</a>}</NavLink>
                </li>

                <li>
                  <NavLink to="/Sustainability" className={({ isActive }) => isActive ? "active" : ""}>{props.active ? "" : <a href="javascript:void(0)">Sustainability</a>} </NavLink>
                </li>
                
                <li>
                  <NavLink to="/SignIn" className={({ isActive }) => isActive ? "active" : ""}>{props.active ? "" : <a href="javascript:void(0)">Sign In</a>}</NavLink>
                </li>
              </ul>

            </div>
          </Collapse>
        </ul>
        <hr /><br />
        {props.active ? "" : <div className="w-100 text-center">
          <img src="assets/button.svg" alt="" onClick={() => {
            props.setisLoggedIn(false);
            window.localStorage.clear();
            navigate("/")
          }} />
        </div>}

      </nav>
    </div>
  );
}
export default Sidebar;