import React, { useEffect, useRef, useState } from 'react';
import "./EditNewBlog.css";
import Advanced from '../../components/Advanced/Advanced';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';

const EditNewBlog = () => {
  const params = useParams();
  const editorRef = useRef();
  const [formData, setFormData] = useState({
    PermaLink: "",
    MetaTitle: "",
    MetaDescription: "",
    HeadScripts: "",
    Title: "",
    Slug: "",
    Excerpt: "",
    FeaturedImage: null,
    FeaturedImageAlt: "",
    RelatedPost: "",
  });
  const [previewImage, setPreviewImage] = useState(null); // State to store preview image URL
  const [postType, setPostType] = useState("draft"); // State to store post type
  const navigate = useNavigate();
  function changeHandler(event) {
    const { name, value, type, files } = event.target;
   

    // Handle file input for image preview
    if (type === "file" && name === "FeaturedImage") {
      const file = files[0];
      setFormData((prev) => ({
        ...prev,
        [name]: file,
      }));
      setPreviewImage(URL.createObjectURL(file)); // Set preview image URL
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value?.toString(),
      }));
    }
  }

  async function submitHandler(event) {
    event.preventDefault();

    const dataToSend = new FormData();
    for (const key in formData) {
      dataToSend.append(key, formData[key]);
    }
    if (editorRef.current) {
      dataToSend.append("PostContent", editorRef.current.getContent());
    }
    
    dataToSend.append("PostType", postType);

    try {
      const response = await fetch(`${process.env.REACT_APP_PROD_URL}/ColorBotUpdateBlog/${params?.id}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${window.localStorage.getItem('token')}`
        },
        body: dataToSend,
      });
      const result = await response.json();
    
      if (response.ok) {
        
        toast.success('Blog Updated successfully saved!');
        navigate('/Blog');
        
      } else {
        toast.error(result.error);
      }
    } catch (error) {
      toast.error('Error submitting Blog data:');
    }
  }


  const fetchBlogData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_PROD_URL}/ColorBotGetBlog/${params?.id}`);
      if (response.ok) {
        const result = await response.json();
        setFormData({
          PermaLink: result.PermaLink,
          MetaTitle: result.MetaTitle,
          MetaDescription: result.MetaDescription,
          HeadScripts: result.HeadScripts,
          Title: result.Title,
          Slug: result.Slug,
          Excerpt: result.Excerpt,
          FeaturedImage: null,
          FeaturedImageAlt: result.FeaturedImageAlt,
          RelatedPost: result.RelatedPost,
        })
        setPreviewImage(result.FeaturedImage);

        setTimeout(()=>{
          editorRef?.current?.setContent(result.PostContent);
        },1000)
     
      }
    } catch (error) {

    }


  };

  useEffect(() => {


    fetchBlogData();
  }, []);
  return (
    <section className="bg-color1">
      <div className="container p-4">
        <div className="row">
          <div className="col-lg-12 add-new-blog">
            <h2>Edit Blog</h2>
          </div>
        </div>
        <div className="form-div">
          <div className="row">
            <form className="p-4" onSubmit={submitHandler}>
              {/* Permalink Field */}
              <div className="form-group">
                <label htmlFor="PermaLink" className="large-label">Permalink</label>
                <input
                  type="text"
                  name="PermaLink"
                  id="PermaLink"
                  value={formData.PermaLink}
                  onChange={changeHandler}
                  className="form-control"
                />
              </div>

              {/* Meta Title Field */}
              <div className="form-group">
                <label htmlFor="MetaTitle" className="large-label">Meta Title</label>
                <input
                  type="text"
                  name="MetaTitle"
                  id="MetaTitle"
                  value={formData.MetaTitle}
                  onChange={changeHandler}
                  className="form-control"
                />
              </div>

              {/* Meta Description Field */}
              <div className="form-group">
                <label htmlFor="MetaDescription" className="large-label">Meta Description</label>
                <textarea
                  name="MetaDescription"
                  id="MetaDescription"
                  value={formData.MetaDescription}
                  onChange={changeHandler}
                  className="form-control"
                  rows="3"
                ></textarea>
              </div>

              {/* Head Scripts Field */}
              <div className="form-group">
                <label htmlFor="HeadScripts" className="large-label">Head Scripts</label>
                <textarea
                  name="HeadScripts"
                  id="HeadScripts"
                  value={formData.HeadScripts}
                  onChange={changeHandler}
                  className="form-control"
                  rows="5"
                ></textarea>
              </div>

              {/* Title Field */}
              <div className="form-group">
                <label htmlFor="Title" className="large-label">Title</label>
                <input
                  type="text"
                  name="Title"
                  id="Title"
                  value={formData.Title}
                  onChange={changeHandler}
                  className="form-control"
                />
              </div>

              {/* Slug Field */}
              <div className="form-group">
                <label htmlFor="Slug" className="large-label">Slug</label>
                <input
                  type="text"
                  name="Slug"
                  id="Slug"
                  value={formData.Slug}
                  onChange={changeHandler}
                  className="form-control"
                />
              </div>

              {/* Featured Image Field */}



              <div className="form-group d-flex">
                <div className="">
                  <label htmlFor="FeaturedImage" className="large-label">Featured Image</label>
                  <div className="image-lable"><img src={previewImage ? previewImage : "assets/images.svg"} style={{
                    height: "150px",
                    width: "180px",
                    borderRadius: "10px"
                  }} /></div>
                </div>
                <div className="choose-field mt-3">
                  <div className="mb-3">
                    <label for="formFile" className="form-label">Choose Featured Image</label>
                    <input
                      type="file"
                      name="FeaturedImage"
                      id="FeaturedImage"
                      onChange={changeHandler}
                      className="form-control"
                    />
                    <span className=" tip text-secondary mt-2 " style={{ font: "12px" }}>703px X 435px</span>
                  </div>

                  <div className="form-group">
                    <label htmlFor="FeaturedImageAlt" className="large-label">Featured Image Alt</label>
                    <input
                      type="text"
                      name="FeaturedImageAlt"
                      id="FeaturedImageAlt"
                      value={formData.FeaturedImageAlt}
                      onChange={changeHandler}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>

              {/* Excerpt Field */}
              <div className="form-group">
                <label htmlFor="Excerpt" className="large-label">Excerpt</label>
                <input
                  type="text"
                  name="Excerpt"
                  id="Excerpt"
                  value={formData.Excerpt}
                  onChange={changeHandler}
                  className="form-control"
                />
              </div>

              {/* Post Content Field */}
              <div className="form-group">
                <label htmlFor="PostContent" className="large-label">Post Content</label>
                <div className='p-2 mt-4'>
                  <Advanced editorRef={editorRef} />
                </div>
              </div>

              {/* Related Post Field */}
              {/* <div className="form-group">
                <label htmlFor="RelatedPost" className="large-label">Related Post</label>
                <select
                  name="RelatedPost"
                  id="RelatedPost"
                  value={formData.RelatedPost}
                  onChange={changeHandler}
                  className="form-select"
                >
                  <option value="">Select Related Post</option>
                  <option value="1">Post One</option>
                  <option value="2">Post Two</option>
                  <option value="3">Post Three</option>
                </select>
              </div> */}

              <div className="bottom-btn justify-content-end align-content-end p-3">
                <button type="submit" className="submit1" onClick={()=>{
                  setPostType("draft");
                }}>Save as Draft</button>
                <button type="submit" className="submit2 m-1" style={{ color: "#FFFFFF" }} onClick={()=>{
                  setPostType("publish");
                }}>Publish Now</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EditNewBlog;
