import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

const Advanced = ({ editorRef }) => {
   

    return (
        <div>
            <Editor

                apiKey='4rrlkexc07nxzat6bvzcywwrwzjjlurw4hzpunmtqyaz4yel'

                onInit={(evt, editor) => (editorRef.current = editor)}
                init={{
                    height: 400,
                    menubar: false,
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                    ],
                   
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
            />
            
        </div>
    );
};

export default Advanced;
